<mat-form-field class="width-full">
  <input
    [formControlName]="amountControlName"
    matInput
    type="text"
    placeholder="0.00"
    appLimitDecimals
    gaxMoneyFormatter
    [currencyCode]="currencyCode"
    required
  />
  <!-- <mat-error *ngIf="amountControl?.errors?.required"
    >Amount is required</mat-error
  > -->
</mat-form-field>
