import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

export interface TableBatchAction {
  icon: string;
  tooltip: string;
  pending: boolean;
  action: () => void;
}

@Component({
  selector: 'gax-page-section-table-header',
  templateUrl: './page-section-table-header.component.html',
  styleUrl: './page-section-table-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageSectionTableHeaderComponent {
  private _search$ = new Subject<string | null>();

  constructor() {
    this._search$
      .pipe(
        map((query) => {
          if (query != null) {
            // trim beginning/trailing whitespace
            query = query.trim();
            // emit null values if there is an empty string so
            // listeners can more easily identify "empty" search queries
            // without having to  empty strings
            return query === '' ? null : query;
          }
          return query;
        }),
        distinctUntilChanged(),
        debounceTime(this.searchChangeDebounce ?? 0),
        takeUntilDestroyed(),
      )
      .subscribe((search) => {
        this.searchQuery = search;
        this.searchChange.emit(search);
      });
  }
  @Input()
  @HostBinding('class.removeHeaderBorder')
  removeHeaderBorder: boolean = false;

  @Input()
  searchChangeDebounce: number | null = 300;

  @Output()
  searchChange: EventEmitter<string | null> = new EventEmitter();

  isFocused!: boolean;

  @HostListener('focus', ['$event']) onFocus() {
    this.isFocused = true;
  }

  @HostListener('blur', ['$event']) onBlur() {
    this.isFocused = false;
  }

  @Input()
  searchbar: boolean = false;

  protected onSearchChange(event: KeyboardEvent) {
    const searchValue = (event.target as HTMLInputElement).value;
    this._search$.next(searchValue);
  }

  searchQuery: string | null = null;

  @Input()
  showFilter: boolean = false;

  @Output()
  filterToggle: EventEmitter<boolean> = new EventEmitter();

  @Input()
  filterActive: boolean = false;

  toggleFilter() {
    this.filterToggle.emit(!this.filterActive);
  }

  @Input()
  showActions: boolean = false;

  @Input()
  actions: TableBatchAction[] = [];
}
