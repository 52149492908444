<mat-form-field class="input--inline" class="autocomplete-caret">
  <input
    matInput
    type="text"
    [matAutocomplete]="autoCurrencyCode"
    [formControlName]="currencyCodeFormName"
  />
  <mat-autocomplete autoActiveFirstOption #autoCurrencyCode="matAutocomplete">
    <mat-option
      *ngFor="let currencyCode of filteredCurrency | async"
      [value]="currencyCode"
    >
      {{ currencyCode }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
