import { Injectable } from '@angular/core';
import { ApplicationContextService } from 'gain-web/lib/application-context/application-context.service';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { lowerCase, startCase, upperCase } from 'lodash-es';

export type EnumValueDisplayFormat =
  | 'display name'
  | 'short display name'
  | 'description'
  | 'sentence case'
  | 'start case'
  | 'lowercase'
  | 'uppercase';

@Injectable({ providedIn: 'root' })
export class EnumDisplayService {
  constructor(private _appContext: ApplicationContextService) {}

  public getEnumDisplay(
    enumType: string,
    enumValue: string,
    format: EnumValueDisplayFormat,
  ): string | null {
    return this._getEnumDisplayValue(enumType, enumValue, format);
  }

  public getEnumDisplayByValue(
    enumValue: string,
    format: EnumValueDisplayFormat,
  ): string | null {
    return this._getEnumDisplayValue(null, enumValue, format);
  }

  private _getEnumDisplayValue(
    enumType: string | null,
    enumValue: string,
    format: EnumValueDisplayFormat,
  ): string | null {
    if (format === 'start case') {
      // FooBar -> Foo Bar
      return startCase(enumValue);
    }
    if (format === 'uppercase') {
      return upperCase(enumValue);
    }
    if (format === 'lowercase') {
      // FooBar -> foo bar
      return lowerCase(enumValue);
    }
    if (format === 'sentence case') {
      const lowered = lowerCase(enumValue);
      return lowered[0].toUpperCase() + lowered.slice(1);
    }
    let displayDetails: ApiClient.IEnumValueDisplayDetails | null = null;
    if (enumType != null) {
      displayDetails = this._appContext.findEnumDisplayDetails(
        enumType,
        enumValue,
      );
    } else {
      const dd = this._appContext.getEnumDisplayDetailsByValue(enumValue);
      if (dd.length === 1) {
        displayDetails = dd[0];
      }
    }

    if (displayDetails == null) {
      return startCase(enumValue);
    } else {
      const { displayName, shortDisplayName, description } = displayDetails;
      if (format === 'display name') {
        return displayName ?? shortDisplayName ?? startCase(enumValue);
      }
      if (format === 'short display name') {
        return shortDisplayName ?? displayName ?? startCase(enumValue);
      }
      if (format === 'description') {
        return description;
      }
    }
    return startCase(enumValue);
  }
}
