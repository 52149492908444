<div class="parent-container">
  <mat-form-field class="autocomplete-caret">
    <div
      [ngClass]="{
        'label-container': displayCountryInfo || displaySocialTaxInfo,
        'label-container-no-icon': !displayCountryInfo || !displaySocialTaxInfo,
      }"
    >
      <div class="label-content">
        <mat-label class="info-description-label">
          {{ countryLabel
          }}<span *ngIf="countryRequired" class="required-marker">*</span>
        </mat-label>
        <gax-help-icon
          *ngIf="displayCountryInfo"
          class="info_description"
          (click)="$event.stopPropagation(); showCountryInformationModal()"
          >help</gax-help-icon
        >
        <gax-help-icon
          *ngIf="displaySocialTaxInfo"
          class="info_description"
          (click)="$event.stopPropagation(); showSocialTaxInformationModal()"
          >help</gax-help-icon
        >
      </div>
    </div>
    <textarea
      matInput
      type="text"
      [matAutocomplete]="countryAutocomplete"
      [formControl]="countryControl"
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      class="overflow-hidden"
    ></textarea>
    <mat-autocomplete
      autoActiveFirstOption
      #countryAutocomplete="matAutocomplete"
      [displayWith]="
        locationFormHelper.displayCountryNameAndCode.bind(locationFormHelper)
      "
      (optionSelected)="optionSelectedEmit($event)"
    >
      <mat-option
        *ngFor="
          let option of locationFormHelper.getCountryOptions(countryControl)
            | async
        "
        [value]="option.code"
        [disabled]="disabledCountries?.includes(option.code) || false"
        >{{ option.code | countryName: true }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="countryControl.hasError('required')">
      {{ countryLabel }} is required.
    </mat-error>
    <mat-error
      *ngIf="this.countryControl.hasError('duplicateIncomeBandingCountry')"
    >
      This country has already been added to the income tax.
    </mat-error>
    <mat-error
      *ngIf="this.countryControl.hasError('duplicateSocialTaxCountry')"
    >
      This country has already been added to the social tax.
    </mat-error>
  </mat-form-field>
</div>

<mat-form-field
  *ngIf="
    locationFormHelper.supportsRegionCalculations(countryControl.value) &&
    regionControl
  "
>
  <mat-label>
    {{ regionLabel }}
  </mat-label>
  <input
    matInput
    type="text"
    [matAutocomplete]="regionAutocomplete"
    [formControl]="regionControl"
  />
  <mat-autocomplete
    autoActiveFirstOption
    #regionAutocomplete="matAutocomplete"
    [displayWith]="
      locationFormHelper
        .displayRegionNameAndCode(countryControl)
        .bind(locationFormHelper)
    "
  >
    <mat-option
      *ngFor="
        let option of locationFormHelper.getSupportedRegionOptions(
          countryControl,
          regionControl
        ) | async
      "
      [value]="option.code"
      [disabled]="disabledCountries?.includes(option.code) || false"
      >{{ option.code | regionName: countryControl.value : true }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="countryControl.hasError('required')"
    >{{ regionLabel }} region is required for
    {{ countryControl.value | countryName }}.
  </mat-error>
  <mat-hint *ngIf="regionControl.invalid && regionControl.pristine"
    >{{ regionLabel }} is required for {{ countryControl.value | countryName }}.
  </mat-hint>
</mat-form-field>
