import { NgModule } from '@angular/core';
import { ConvertibleMoneyComponent } from 'gain-web/shared-modules/money/convertible-money/convertible-money.component';
import { GaMoneyModule } from 'gain-lib/money';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ConvertibleMoneyComponent],
  imports: [CommonModule, GaMoneyModule, MatTooltipModule, MatIconModule],
  exports: [ConvertibleMoneyComponent, GaMoneyModule],
})
export class GainMoneyModule {}
