import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
  ValidatorFn,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { autocompleteOptionValidator } from 'projects/gain-lib/custom-validators/autocompleteOption.validator';
import { CurrencyCode, currencyCodes } from 'gain-lib/money/src/currency';

@Component({
  selector: 'gax-money-currency-code',
  templateUrl: './money-currency-code.component.html',
  styleUrls: ['./money-currency-code.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoneyCurrencyCodeComponent implements OnInit {
  @Input() currencyCodeControl!: FormControl<string>;
  @Input() currencyCodeFormName: string = 'currencyCode';

  @Input() filteredCurrency!: Observable<CurrencyCode[]>;
  currencyOptions: readonly CurrencyCode[] = currencyCodes;
  currencyOptionValidator: ValidatorFn;

  constructor(private formGroupDirective: FormGroupDirective) {
    // Add validator to ensure the user selects an option from the currency code dropdown
    this.currencyOptionValidator = autocompleteOptionValidator(
      this.currencyOptions.map((c) => c),
    );
  }

  ngOnInit() {
    this.currencyCodeControl = this.formGroupDirective.form.controls[
      this.currencyCodeFormName
    ] as FormControl;

    this.filteredCurrency = this.currencyCodeControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCurrency(value || '')),
    );
    this.currencyCodeControl.addValidators([this.currencyOptionValidator]);
  }

  private _filterCurrency(value: string): CurrencyCode[] {
    const filterValue = value.toLowerCase();
    return this.currencyOptions.filter((option) =>
      option.toLowerCase().includes(filterValue),
    );
  }
}
