import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'gax-money-amount',
  templateUrl: './money-amount.component.html',
  styleUrls: ['./money-amount.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoneyAmountComponent {
  @Input() amountControlName: string = 'amount';
  @Input() amountControl!: FormControl<number>;
  @Input() currencyCodeControl!: FormControl<string>;

  private _currencyCode!: string;

  constructor(private formGroupDirective: FormGroupDirective) {}

  @Input() set currencyCode(value: string) {
    this._currencyCode = value;
  }

  get currencyCode(): string {
    return this._currencyCode;
  }
}
