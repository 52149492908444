import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LimitDecimalsModule } from 'projects/gain-lib/limit-decimals/limit-decimals.module';
import { CurrencyCodeNamePipe } from 'projects/gain-lib/money/src/currency-code-name.pipe';
import { CurrencyNamePipe } from 'projects/gain-lib/money/src/currency-name.pipe';
import { MoneyAmountComponent } from 'projects/gain-lib/money/src/money-amount/money-amount.component';
import { MoneyCurrencyCodeComponent } from 'projects/gain-lib/money/src/money-currency-code/money-currency-code.component';
import { MoneyFormComponent } from 'projects/gain-lib/money/src/money-form/money-form.component';
import { MoneyFormatterDirective } from 'projects/gain-lib/money/src/money-formatter.directive';
import { MoneyPipe } from 'projects/gain-lib/money/src/money.pipe';
import { MoneyComponent } from 'projects/gain-lib/money/src/money/money.component';

@NgModule({
  declarations: [
    MoneyComponent,
    CurrencyNamePipe,
    CurrencyCodeNamePipe,
    MoneyFormatterDirective,
    MoneyPipe,
    MoneyFormComponent,
    MoneyAmountComponent,
    MoneyCurrencyCodeComponent,
  ],
  exports: [
    MoneyComponent,
    CurrencyNamePipe,
    CurrencyCodeNamePipe,
    MoneyFormatterDirective,
    MoneyPipe,
    MoneyFormComponent,
    MoneyAmountComponent,
    MoneyCurrencyCodeComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,

    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    LimitDecimalsModule,
  ],
  providers: [MoneyPipe],
})
export class GaMoneyModule {}
