import { Pipe, PipeTransform } from '@angular/core';
import {
  EnumDisplayService,
  EnumValueDisplayFormat,
} from 'gain-web/lib/enums/enum-display.service';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';

@Pipe({ name: 'planTypeDisplay', standalone: true })
export class PlanTypeDisplayPipe implements PipeTransform {
  constructor(private _enumDisplay: EnumDisplayService) {}

  transform(
    value: ApiClient.PlanType | null | undefined,
    format: EnumValueDisplayFormat = 'display name',
  ): string | null {
    if (value == null) {
      return null;
    }
    return this._enumDisplay.getEnumDisplay('PlanType', value, format);
  }
}
