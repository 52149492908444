import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryNamePipe, RegionNamePipe } from 'gain-lib/geography';

@NgModule({
  declarations: [CountryNamePipe, RegionNamePipe],
  imports: [CommonModule],
  exports: [CountryNamePipe, RegionNamePipe],
})
export class GaGeographyModule {}
