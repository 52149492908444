<div class="table-viewport">
  <ng-content></ng-content>
</div>

@switch (tableState$ | async) {
  @case ("EMPTY") {
    <div class="zero-results results-placeholder">
      <div class="placeholder-content">
        <img
          priority
          ngSrc="/ga-assets/illustrations/question_hires.png"
          alt="Are you sure?"
          height="159"
          width="168"
        />
        <h2>No results found</h2>
        @if (_zeroResultsMessageCmp) {
          <div>
            <ng-content select="gax-table-zero-results-message"></ng-content>
          </div>
        } @else {}
      </div>
    </div>
  }
  @case ("LOADED") {
    <!-- Do nothing, table loaded successfully -->
  }
  @case ("LOADING") {
    <mat-spinner [diameter]="24"></mat-spinner>
    <div class="scroll-overlay"></div>
    <div
      [ngClass]="{
        'loading-overlay': true,
        empty: (empty$ | async),
      }"
    ></div>
    @if (empty$ | async) {
      <div class="empty-placeholder results-placeholder"></div>
    }
  }
  @case ("ERROR") {
    <div class="error-result results-placeholder">
      <div class="placeholder-content">
        <img
          ngSrc="/ga-assets/illustrations/question_hires.png"
          alt="Error fetching data."
          height="159"
          width="168"
        />
        <h2>Error loading data</h2>
        @if (_tableErrorMessageCmp) {
          <div>
            <ng-content select="gax-table-error-message"></ng-content>
          </div>
        } @else {
          <div>
            <span>There was an error retrieving the requested data.</span>
          </div>
        }
      </div>
    </div>
  }
}
