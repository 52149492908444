import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageBackLinkComponent } from './page-back-link/page-back-link.component';
import { PageFooterActionsComponent } from './page-footer/page-footer-actions/page-footer-actions.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { PageHeaderActionsComponent } from './page-header/page-header-actions/page-header-actions.component';
import { PageHeaderDescriptionComponent } from './page-header/page-header-description/page-header-description.component';
import { PageHeaderTitleComponent } from './page-header/page-header-title/page-header-title.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageSectionContentComponent } from './page-section/page-section-content/page-section-content.component';
import { PageSectionHeaderActionsComponent } from './page-section/page-section-header-actions/page-section-header-actions.component';
import { PageSectionHeaderDescriptionComponent } from './page-section/page-section-header/page-section-header-description/page-section-header-description.component';
import { PageSectionHeaderTitleComponent } from './page-section/page-section-header/page-section-header-title/page-section-header-title.component';
import { PageSectionHeaderComponent } from './page-section/page-section-header/page-section-header.component';
import { PageSectionTableContentComponent } from './page-section/page-section-table-content/page-section-table-content.component';
import { PageSectionComponent } from './page-section/page-section.component';
import { PageComponent } from './page.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { PageSectionTableHeaderComponent } from 'gain-lib/page/page-section/page-section-table-header/page-section-table-header.component';
import { GaProcessMonitorModule } from 'gain-lib/ga-process-monitor/process-monitor.module';

@NgModule({
  declarations: [
    PageComponent,
    PageHeaderComponent,
    PageHeaderActionsComponent,
    PageSectionComponent,
    PageSectionHeaderComponent,
    PageSectionHeaderActionsComponent,
    PageFooterComponent,
    PageFooterActionsComponent,
    PageHeaderTitleComponent,
    PageHeaderDescriptionComponent,
    PageSectionContentComponent,
    PageSectionHeaderTitleComponent,
    PageSectionHeaderDescriptionComponent,
    PageBackLinkComponent,
    PageSectionTableHeaderComponent,
    PageSectionTableContentComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    GaProcessMonitorModule,
  ],
  exports: [
    PageComponent,
    PageHeaderComponent,
    PageHeaderActionsComponent,
    PageSectionComponent,
    PageSectionTableHeaderComponent,
    PageSectionHeaderComponent,
    PageSectionHeaderActionsComponent,
    PageFooterComponent,
    PageFooterActionsComponent,
    PageHeaderTitleComponent,
    PageHeaderDescriptionComponent,
    PageSectionContentComponent,
    PageSectionHeaderTitleComponent,
    PageSectionHeaderDescriptionComponent,
    PageSectionTableHeaderComponent,
    PageBackLinkComponent,
    PageSectionTableContentComponent,
  ],
})
export class PageModule {}
