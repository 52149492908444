import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'gax-money-form',
  templateUrl: './money-form.component.html',
  styleUrls: ['./money-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MoneyFormComponent implements OnInit {
  @Input() currencyCodeControl!: FormControl<string>;
  @Input() currencyCodeFormName: string = 'currencyCode';
  @Input() amountControlName: string = 'amount';

  constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    this.currencyCodeControl = this.formGroupDirective.form.get(
      this.currencyCodeFormName,
    ) as FormControl;
  }

  private _currencyCode!: string;

  @Input() set currencyCode(value: string) {
    this._currencyCode = value;
  }

  get currencyCode(): string {
    return this._currencyCode;
  }
}
