import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { orderBy, uniqBy } from 'lodash';

interface CountryDataCacheEntry {
  timestamp: string;
  countries: ApiClient.ICountryDto[];
}

const localStorageKey = 'CountryRegionData';

function getFromLocalStorage(): CountryDataCacheEntry | null {
  const dataStr: string | null = window.localStorage.getItem(localStorageKey);
  if (dataStr == null) {
    return null;
  }
  return JSON.parse(dataStr) as CountryDataCacheEntry;
}

const expirationPeriodInMs = 60 * 60 * 1000;

function writeToLocalStorage(countries: ApiClient.ICountryDto[]) {
  const entry: CountryDataCacheEntry = {
    timestamp: new Date().toISOString(),
    countries,
  };
  const entryJson = JSON.stringify(entry, null, 2);
  window.localStorage.setItem(localStorageKey, entryJson);
}

@Injectable({ providedIn: 'root' })
export class CountryRegionDataService implements Resolve<void> {
  private _countries: ApiClient.ICountryDto[] = [];
  private _countriesSet: Set<string> = new Set<string>();
  private _countriesWithRegions: ApiClient.ICountryDto[] = [];
  private _regionsSet: Set<string> = new Set<string>();

  public get countries() {
    if (!this._initialized) {
      throw new Error(
        'CountryRegionDataService: Country data not initialized.',
      );
    }
    return this._countries;
  }

  public get countriesWithRegions() {
    if (!this._initialized) {
      throw new Error(
        'CountryRegionDataService: Country data not initialized.',
      );
    }
    return this._countriesWithRegions;
  }

  public get currencyCodes() {
    return this.currencies.map((c) => c.code);
  }

  public get currencies() {
    const currencies = this.countries
      .filter((c) => c.currency != null)
      .map((c) => c.currency!);
    return uniqBy(
      orderBy(currencies, (c) => c.code),
      (c) => c.code,
    );
  }

  private _initialized = false;

  constructor(private _api: ApiClient.CountryRegionDataBffService) {}

  findCountryByCode(code: string): ApiClient.ICountryDto | undefined {
    return this._countries.find(
      (c) => c.code.toLowerCase() === code.toLowerCase(),
    );
  }

  getAllCountriesByNameOrCode(query: string) {
    const queryLower = query.toLowerCase();
    return this._countries.filter(
      (c) =>
        c.code.toLowerCase().includes(queryLower) ||
        c.name.toLowerCase().includes(queryLower),
    );
  }

  findCountryByCurrencyCode(
    currencyCode: string,
  ): ApiClient.ICountryDto | undefined {
    return this._countries.find((c) => c.currency?.code === currencyCode);
  }

  findCurrencyByCountryCode(
    countryCode: string,
  ): ApiClient.ICurrencyDto | null {
    return (
      this._countries.find((c) => c.code === countryCode)?.currency || null
    );
  }

  getSupportedRegionOptions({
    countryCode,
    regionQuery,
  }: {
    countryCode: string;
    regionQuery?: string | null;
  }) {
    const country = this.findCountryByCode(countryCode);
    const regions =
      country != null && country.supportsRegionCalculations
        ? country.regions
        : [];
    const regionQueryLower = regionQuery?.toLowerCase();
    return regionQueryLower != null
      ? regions.filter(
          (r) =>
            r.name.toLowerCase().includes(regionQueryLower) ||
            r.code.toLowerCase().includes(regionQueryLower),
        )
      : regions;
  }

  getSupportedRegionsByCountryCode(
    code: string[] | string,
  ): ApiClient.IRegionDto[] | undefined {
    // If code is a string, make it an array first
    code = Array.isArray(code) ? code : [code];

    const regions = code
      // Make an array of regions for all country codes provided
      .reduce(
        (r, c) => r.concat(this.findCountryByCode(c) || []),
        [] as ApiClient.ICountryDto[],
      )
      // Filter out only countries that have regions
      .filter((c) => c.supportsRegionCalculations)
      // Flatten the array
      .flatMap((c) => c.regions.sort(this._sortFn));

    return regions.length ? regions : undefined;
  }

  getSupportedRegionsAndCountryByCountryCode(
    code: string[] | string,
  ): { countryCode: string; region: ApiClient.IRegionDto }[] | undefined {
    // If code is a string, make it an array first
    code = Array.isArray(code) ? code : [code];

    const regions = code
      // Make an array of regions for all country codes provided
      .reduce(
        (r, c) => r.concat(this.findCountryByCode(c) || []),
        [] as ApiClient.ICountryDto[],
      )
      // Filter out only countries that have regions
      .filter((c) => c.supportsRegionCalculations)
      // Flatten the array
      .flatMap((c) => {
        return c.regions.sort(this._sortFn).map((region) => {
          return {
            countryCode: c.code,
            region: region,
          };
        });
      });

    return regions.length ? regions : undefined;
  }

  async resolve(): Promise<void> {
    return this._initialize();
  }

  private async _initialize(): Promise<void> {
    let cached = getFromLocalStorage();

    if (
      cached == null ||
      new Date().getTime() - new Date(cached.timestamp).getTime() >
        expirationPeriodInMs
    ) {
      if (cached == null) {
        // eslint-disable-next-line no-console
        console.debug('no cached country data, fetching');
      } else {
        // eslint-disable-next-line no-console
        console.debug('country data in cache expired, re-fetching');
      }

      const result = await this._api.getCountryRegionData().toPromise();

      const countries = result.countries
        .map((c) => ({
          ...c,
          regions: c.regions.sort(this._sortFn),
        }))
        .sort(this._sortFn);
      writeToLocalStorage(countries);
    } else {
      // eslint-disable-next-line no-console
      console.debug('restored country data from cache');
    }

    cached = getFromLocalStorage();

    if (cached != null) {
      const countries = cached.countries
        .map((c) => ({
          ...c,
          regions: c.regions.sort(this._sortFn),
        }))
        .sort(this._sortFn);

      this._countries = countries;
      this._countriesSet = new Set(this._countries.map((c) => c.code));
      this._countriesWithRegions = countries.filter(
        (c) => c.supportsRegionCalculations,
      );
      this._regionsSet = new Set(
        this._countriesWithRegions.flatMap((c) =>
          c.regions.map((r) => `${c.code}-${r.code}`),
        ),
      );
    }

    this._initialized = true;
  }

  public isValidCountry(countryCode: string): boolean {
    return this._countriesSet.has(countryCode);
  }

  public isValidRegion(countryCode: string, regionCode: string): boolean {
    return this._regionsSet.has(`${countryCode}-${regionCode}`);
  }

  private _sortFn(
    a: ApiClient.ICountryDto | ApiClient.IRegionDto,
    b: ApiClient.ICountryDto | ApiClient.IRegionDto,
  ): number {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }
}
