import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { GaIconModule } from 'gain-lib/icon';
import { GaInputFormFieldComponent } from 'gain-lib/input-form-field';
import { TableCellValueEditComponent } from 'gain-lib/table-cell-value-edit';
import { TableCellValueEditNewComponent } from 'gain-lib/table-cell-value-edit-new/src/table-cell-value-edit.component-new';
import { GATableSelectFormFieldModule } from 'gain-lib/table-select-form-field';
import { GaEditedHighlightDirective } from './ga-edited-highlight.directive';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatTableWrapperComponent } from 'gain-lib/ga-table/src/ga-table/mat-table-wrapper/mat-table-wrapper.component';
import { TableZeroResultsMessageComponent } from 'gain-lib/ga-table/src/ga-table/mat-table-wrapper/table-zero-results-message/table-zero-results-message.component';
import { TableErrorMessageComponent } from 'gain-lib/ga-table/src/ga-table/mat-table-wrapper/table-error-message/table-error-message.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GA_MAT_PAGINATOR_OPTIONS } from 'gain-lib/ga-table/src/ga-table/pagination';
import { MatSortModule } from '@angular/material/sort';
import { EditableCellWrapperComponent } from 'gain-lib/ga-table/src/ga-table/editable-cell-wrapper/editable-cell-wrapper.component';

@NgModule({
  declarations: [
    GaEditedHighlightDirective,
    MatTableWrapperComponent,
    TableZeroResultsMessageComponent,
    TableErrorMessageComponent,
    EditableCellWrapperComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    TableCellValueEditComponent,
    TableCellValueEditNewComponent,
    GATableSelectFormFieldModule,
    MatFormFieldModule,
    GaInputFormFieldComponent,
    GaIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    NgOptimizedImage,
  ],
  exports: [
    GaEditedHighlightDirective,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    TableCellValueEditComponent,
    TableCellValueEditNewComponent,
    GATableSelectFormFieldModule,
    MatFormFieldModule,
    GaInputFormFieldComponent,
    GaIconModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableWrapperComponent,
    TableZeroResultsMessageComponent,
    TableErrorMessageComponent,
    EditableCellWrapperComponent,
  ],
  providers: [
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: GA_MAT_PAGINATOR_OPTIONS,
    },
  ],
})
export class GaTableModule {}
