import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const autocompleteOptionValidator = (
  autocompleteOptions: string[] = [],
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null =>
    control.value == null || autocompleteOptions.includes(control.value)
      ? null
      : { invalidOption: true };
};
