import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PageEditTracker } from 'gain-lib/ga-page-editing/page-edit-tracker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gax-editable-cell-wrapper',
  templateUrl: './editable-cell-wrapper.component.html',
  styleUrl: './editable-cell-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableCellWrapperComponent
  implements OnChanges, OnInit, OnDestroy
{
  value = input.required<unknown>();
  onDestroy$ = new Subject<void>();
  private _saved: boolean = false;
  private _edited: boolean = false;
  private _recentlyEdited = false;
  private _previouslyEdited = false;
  private _savedValue?: unknown;

  protected get sameAsSaved() {
    return this._savedValue === this.value();
  }

  @HostBinding('class')
  get classes(): Record<string, boolean> {
    return {
      saved: this._saved,
      edited: this._edited,
      'previously-edited': this._previouslyEdited,
      'recently-edited': this._recentlyEdited,
      'same-as-saved': this.sameAsSaved,
    };
  }

  constructor(
    private _cd: ChangeDetectorRef,
    private _pageEdits: PageEditTracker,
  ) {}

  ngOnInit() {
    this._pageEdits.saved$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((saved) => {
        if (saved) {
          this.markAsSaved();
        }
      });
  }

  public markAsEdited() {
    this.markAsUnsaved();
    this._edited = true;
    this._recentlyEdited = true;
    this._pageEdits.markAsEdited();
    this._cd.markForCheck();
    setTimeout(() => {
      this._recentlyEdited = false;
      this._cd.markForCheck();
    }, 1000);
  }

  public markAsUnsaved() {
    if (this._saved) {
      this._saved = false;
      this._cd.markForCheck();
    }
  }

  public markAsSaved() {
    this._previouslyEdited = this._edited;
    this._saved = true;
    this._edited = false;
    this._savedValue = this.value();
    setTimeout(() => {
      this._previouslyEdited = this._edited;
      this._cd.markForCheck();
    }, 3000);
    this._cd.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    const valueChanges = changes.value;
    if (valueChanges != null) {
      if (valueChanges.isFirstChange()) {
        this.markAsSaved();
      } else {
        this.markAsEdited();
      }
    }
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
