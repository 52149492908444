<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- Need two different header paddings - with and without actions -->

<!-- Need two different header paddings - with and without actions -->
<div>
  <ng-content select="gax-page-section-header-title"></ng-content>
</div>
<ng-content></ng-content>
<div class="header-options">
  @if (showActions) {
    @for (action of actions; track action; let index = $index) {
      <button
        mat-icon-button
        aria-label="checkout button tooltip"
        class="section-header-table-batch-action"
        [ngClass]="{ pending: actions[index].pending }"
        (click)="actions[index].action()"
      >
        <gax-button-spinner></gax-button-spinner>
        <mat-icon class="material-symbols-outlined"
          >{{ actions[index].icon }}
        </mat-icon>
      </button>
    }
  }

  <div
    *ngIf="searchbar"
    (click)="searchBar.focus()"
    class="section-header-table-search-bar"
  >
    <mat-icon>search</mat-icon>
    <input
      (focus)="onFocus()"
      (blur)="onBlur()"
      [class.focused]="isFocused || (searchBar.value.length > 0 && !isFocused)"
      #searchBar
      matInput
      type="text"
      (keyup)="onSearchChange($event)"
      placeholder="Search"
    />
  </div>
  @if (showFilter) {
    <div
      (click)="toggleFilter()"
      class="section-header-table-filter"
      [class.active]="filterActive"
    >
      <mat-icon>filter_list</mat-icon>
    </div>
  }
</div>
