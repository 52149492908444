<span title="{{ currencyName$ | async }}">{{ currencyCode$ | async }}</span>
@if (roundingBehavior === "none") {
  <span [title]="amount$ | async | number: '1.2-99'">
    {{ amount$ | async | number: "1.2-99" }}
  </span>
} @else {
  <span [title]="amount$ | async | number: digitsInfo">
    {{ amount$ | async | number: digitsInfo }}
  </span>
}
