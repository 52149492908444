import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  input,
  model,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import BigNumber from 'bignumber.js';
import { DocumentEventService } from 'gain-lib/services/documentClickDetectionService.service';
import { Subscription } from 'rxjs';
import { GainMoneyModule } from 'gain-web/shared-modules/money/money.module';
import { GainPercentPipe } from 'gain-web/lib/percent/gain-percent.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type ValueType = 'percent' | 'number' | 'string';

type AlignmentBehavior = 'left' | 'right';
type AlignmentBehaviorOptions = 'auto' | AlignmentBehavior;

@Component({
  selector: 'gax-table-cell-value-edit',
  templateUrl: './table-cell-value-edit.component.html',
  styleUrl: './table-cell-value-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    GainMoneyModule,
    GainPercentPipe,
  ],
})
export class TableCellValueEditComponent {
  valueType = input<ValueType>('string');
  value = model.required<string | number | null>();
  valueChange = new EventEmitter<string | number | null>();
  precisionDigits = input<number>(2);
  min = input<number | null>();
  max = input<number | null>();
  icon = input<string | null>();
  align = input<AlignmentBehaviorOptions>('auto');

  @HostBinding('class.edited')
  edited?: boolean | undefined | null;

  @HostBinding('class.left-align')
  get leftAlign() {
    return this.alignmentBehavior === 'left';
  }

  get alignmentBehavior(): AlignmentBehavior {
    const option = this.align();
    if (option === 'auto') {
      return this.valueType() === 'percent' || this.valueType() === 'number'
        ? // right aligned for numeric/money types unless otherwise specified
          'right'
        : // left aligned for other types unless otherwise specified
          'left';
    } else {
      return option;
    }
  }

  protected get hasCustomIcon() {
    return this.icon != null;
  }

  protected get userEditableValue() {
    const value = this.value();
    if (this.valueType() === 'percent' && value != null) {
      return new BigNumber(value).times(100).toNumber();
    }
    return value;
  }

  @HostBinding('class.editing')
  editing: boolean = false;

  @HostBinding('class.clean')
  isClean: boolean = true;

  documentClickSubscription!: Subscription;

  constructor(
    private elRef: ElementRef,
    private documentEventService: DocumentEventService,
    private cd: ChangeDetectorRef,
  ) {
    this.documentClickSubscription = this.documentEventService.click$
      .pipe(takeUntilDestroyed())
      .subscribe((event) => {
        this.documentClickListener(event.target as HTMLElement);
      });
    this.value.subscribe((v) => {
      this.valueChange.emit(v);
    });
  }

  /**
   * Handles the click event outside of the component element.
   * If the target is inside the component element, it does nothing.
   * If the target is outside the component element, it calls the `exitEditMode` method.
   * @param target - The target element that triggered the click event.
   */
  documentClickListener(target: HTMLElement | undefined): void {
    if (target && this.elRef.nativeElement.contains(target)) {
      // console.log('Clicked inside');
    } else {
      // Clicked outside
      this.exitEditMode();
    }
  }

  onInputChange() {
    this.isClean = false;
  }

  enterEditMode(element: HTMLDivElement) {
    this.editing = true;
    setTimeout(() => {
      const inputBox = element.querySelector('input');
      if (inputBox) inputBox.focus();
      this.cd.detectChanges();
      this.cd.markForCheck();
    }, 100);
  }

  exitEditMode() {
    this.editing = false;
    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  // Update row with new value amount
  updateValue(newValue: string, event: Event) {
    //This stops the expansion panel from opening after the parentRow is edited
    event.stopPropagation();
    if (!newValue && this.valueType() === 'number') return;
    // if value is the same as before, do nothing
    if (newValue === this.value()) {
      return;
    }

    if (this.valueType() === 'string') {
      this.value.set(newValue);
      this.edited = true;
      this.exitEditMode();
      return;
    }

    const numberValue = parseFloat(newValue);
    const isNegative = Math.sign(numberValue) === -1 ? true : false;
    if (isNegative) return;

    if (this.valueType() === 'percent') {
      if (numberValue > 100) {
        return;
      }
      this.value.set(new BigNumber(numberValue).dividedBy(100).toNumber());
    } else {
      this.value.set(numberValue);
    }

    this.edited = true;
    this.exitEditMode();
  }
}
